
/////////////////////////////
// IMAGES
/////////////////////////////
.alignright, .alignleft, .alignmiddle, .alignnone {
	width: 100% !important;
	float: none;
	margin: 0 0 20px 0;
	display: block;

	img {
		width: 100%; } }

.aligncenter {
	margin: 5px auto 20px auto;
	text-align: center;
	max-width: 100%;
	height: auto;

	img {
		margin: 0 auto; } }

.wp-caption-text {
	font-style: italic;
	text-align: center;
	font-family: 'Georgia', serif; }



//////////////////////////////////////
// RESPONSIVE
//////////////////////////////////////

//@media (min-width: 550px)

//@media (min-width: 768px)

@media (min-width: 800px) {

	.alignleft, .alignright, .aligncenter, .alignnone {
		margin: 5px 0 20px 0;
		width: auto !important;
		max-width: 50%;
		display: block;

		img {
			width: auto;
			max-width: 100%; } }

	.alignleft {
		float: left;
		margin-right: 20px; }

	.alignright {
		float: right;
		margin-left: 20px; } }

//@media (min-width: 1024px)

//@media (min-width: 1100px)
