@import 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
@import 'https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900,900i';
@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700';




$dark-gray: #b2b3b2;
$light-grey: #efefef;
$charcoal: #000000;

$dark-green: #4b9560;
$light-green: #c4d600;
$blue: #007dba;
$dark-blue: #002e5d;

$baseFont: 'proxima-nova', Arial, Helvetica, sans-serif;
$heading: 'proxima-nova-extra-condensed', Arial, Helvetica, sans-serif;
$heading2: 'proxima-nova-extra-condensed', Arial, Helvetica, sans-serif;

$center-transform: translate(-50%, -50%);

* {
	box-sizing: border-box; }

header, footer, section, main, nav, label {
	display: block; }

body {
	padding: 60px 0 0 0;
	margin: 0;
	background-color: #fff;
	font-family: $baseFont;
	font-size: 16px;
	line-height: 1.5em;
	color: #000;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
	text-size-adjust: none; }

a {
	text-decoration: none;
	color: $blue;

	&:hover {
		text-decoration: underline; }

	img {
		border:	0; } }

h1, h2, h3, h4, h5 {
	margin: 0 0 10px 0;
	font-family: $heading;
	line-height: 1.2em;
	color: $charcoal;
	font-weight: normal;
	text-transform: uppercase;

	&:last-child {
		margin-bottom: 0; } }


h1 {
	font-size: 2em;
	margin-bottom: 20px;
	font-family: $baseFont;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.09em;
	line-height: 1em; }

h2 {
	font-size: 1.8em;
	font-weight: 100;
	letter-spacing: 0.05em; }

h3 {
	font-size: 1.5em;
	text-transform: uppercase;
	font-family: $heading2;
	letter-spacing: 0.05em; }

h4 {
	font-size: 1em;
	margin-bottom: 5px;
	font-weight: 600;
	line-height: 1.5em;
	text-transform: uppercase;
	font-family: $baseFont; }

h5 {
	font-size: 1.6em;
	line-height: 1.3em;
	font-weight: 300;
	font-family: $baseFont;
	color: #666;
	margin-bottom: 20px;
	letter-spacing: normal; }

p {
	margin: 0 0 20px 0;

	&:last-child {
		margin-bottom: 0; } }

strong, b {
	font-weight: bold; }

blockquote {
	padding: 0;
	margin: 0 auto 20px auto;
	max-width: 800px;
	font-size: 2em;
	line-height: 1.2em;
	font-family: $baseFont;
	font-weight: normal;

	p {
		margin-bottom: 10px; }

	strong {
		font-weight: 100;
		text-transform: uppercase;
		font-size: 16px; }

	hr {
		margin: 0 auto; } }

hr {
	clear: both;
	height: 0;
	width: 100px;
	border: solid #000;
	border-width: 0 0 2px 0;
	margin: 10px 0; }

table, th, tbody, tr, td {
	border:	0; }

table {
	margin-bottom: 20px;
	clear: both;
	border-spacing: 0;
	line-height: 1.2em;

	tr {
		td {
			padding: 10px 10px 10px 0;
			border: solid #ccc;
			border-width: 1px 0 0 0; }

		&:last-child {
			td {
				border-width: 1px 0; } } } }

.tableBox {
	width: 100%;
	overflow: auto; }


.fa {
	font-family: $heading;
	position: relative;
	padding-left: 40px !important; }

.fa:before {
	font-family: 'FontAwesome'; }







.white-text {
	color: #fff;

	h1, h2, h3, h4, h5 {
		color: #fff; }

	p {
		color: #fff; }

	hr {
		border-color: #fff; } }
















//////////////////////////////////////
// RESPONSIVE
//////////////////////////////////////

//@media (min-width: 550px)

@media (min-width: 768px) {

	body {
		padding-top: 80px; }

	h1 {
		font-size: 2.5em;
		line-height: 1.1em; }

	h2 {
		font-size: 2.2em; }

	h3 {
		font-size: 1.8em; }

	h4 {
		font-size: 1em; }

	h5 {
		font-size: 1.8em; } }

//@media (min-width: 900px)

//@media (min-width: 1024px)

@media (min-width: 1100px) {

	h1 {
		font-size: 3em; }

	h2 {
		font-size: 2.2em; }

	h3 {
		font-size: 1.8em; }

	h4 {
		font-size: 1em; }

	h5 {
		font-size: 1.8em; } }

@media (min-width: 1200px) {

	body {
		padding-top: 100px; } }
