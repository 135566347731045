



// COUNTUP
.countup {
	position: relative;
	.spacer {
		opacity: 0; }
	.number {
		position: absolute;
		top: 50%;
		right: 0;
		text-align: right;
		transform: translateY(-50%); } }


// COUNTUP WITH CIRCLE GRAPH
.circleGraph {
	display: inline-block;
	width: 140px;
	height: 140px;
	text-align: center;
	background: url(/wp-content/uploads/2017/03/circle-graph-bg.png) center no-repeat;
	background-size: cover;
	position: relative;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background: url(/wp-content/uploads/2017/03/circle-graph-diamond.png) center no-repeat;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0; }

	.text {
		position: absolute;
		top: 50%;
		left: 50%;
		max-width: 80%;
		transform: translate(-50%, calc(-50% + 5px)); }

	h4 {
		font-size: 40px;
		line-height: 36px;
		margin: 0;
		letter-spacing: -0.05em;
		font-weight: normal; }

	p {
		font-size: 10px;
		text-transform: uppercase;
		margin: 0; }

	svg {
		fill: none;
		stroke: #002e5d;
		stroke-dasharray: 0 400;
		stroke-dashoffset: 0;
		stroke-width: 3;
		width: 95%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-85deg);
		transition: all 1.5s cubic-bezier(0, 0, 0.18, 1); } }





// BREADCRUMB
#breadcrumb {

	main {
		padding: 10px 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; }

	a {
		margin-right: 20px;
		position: relative;
		display: inline-block;


		&:after {
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			border: solid $blue;
			border-width: 1px 1px 0 0;
			position: absolute;
			right: -9px;
			top: 45%;
			transform: rotate(45deg) translateY(-50%); } }

	b, strong {
		color: #fff; } }





// BACK TO TOP BUTTON
#backToTop {
	display: block;
	position: fixed;
	right: 20px;
	bottom: 20px;
	width: 50px;
	height: 50px;
	background: $charcoal;
	border-radius: 0;
	cursor: pointer;
	transition: all 0.2s ease-out, visibility 0s 0.2s;
	opacity: 0;
	visibility: hidden;
	z-index: 3;

	&:hover {
		background: $blue; }

	&:before {
		content: '';
		display: block;
		width: 35%;
		height: 35%;
		border: solid #ffffff;
		border-width: 4px 4px 0 0;
		position: absolute;
		top: 57%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg); }

	&.show {
		transition: all 0.2s ease-out, visibility 0s 0s;
		opacity: 1;
		visibility: visible; } }





// PHOTO GALLERY
.photo-gallery {
	padding: 20px 0 10px 0;
	border: solid #ccc;
	border-width: 0;
	margin-bottom: 20px;

	.gallery-thumb {
		width: auto;
		margin: 0 0 20px 0;
		position: relative;
		overflow: hidden;
		display: block;

		.image {
			width: 100%;
			height: 50vw;
			position: relative;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			border: 1px solid #ccc;
			overflow: hidden;

			img {
				max-height: calc(100% - 20px);
				max-width: calc(100% - 20px);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				&:last-child {
					display: none; } }

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.5) url(/wp-content/themes/uc/images/zoomIcon.svg) center center no-repeat;
				background-size: 40px;
				transition: all 0.2s ease-out;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0; } }

		.caption {
			padding: 10px 5px 0 5px;
			text-align: center;
			line-height: 1.2em;
			font-weight: normal;
			font-style: italic; }

		&:hover {
			text-decoration: none;

			.image:after {
				opacity: 1; } } } }





// FANCYBOX
#fancybox-overlay {
	background-color: #000 !important; }

#fancybox-close {
	position: fixed;
	top: 0;
	right: 0;
	background-image: none;
	cursor: pointer;
	height: 60px;
	width: 60px;

	&:hover {
		opacity: 1; }

	&:before, &:after {
		content: '';
		height: 60%;
		width: 4px;
		position: absolute;
		top: 50%;
		left: 50%;
		background-color: #fff;
		transform: translate(-50%, -50%) rotate(45deg); }

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg); } }

#fancybox-left, #fancybox-right {
	position: fixed;
	width: 50%;
	visibility: visible;
	display: block;

	&:hover {
		opacity: 1; }

	span {
		display: none; }

	&:after {
		content: '';
		width: 30px;
		height: 30px;
		display: block;
		border: solid #fff;
		border-width: 4px 4px 0 0;
		transform: translateY(-50%) rotate(45deg);
		position: absolute;
		top: 50%;
		right: 40px; } }

#fancybox-left:after {
	transform: translateY(-50%) rotate(-135deg);
	right: auto;
	left: 40px; }

.fancybox-bg {
	display: none; }














//////////////////////////////////////
// RESPONSIVE
//////////////////////////////////////

//@media (min-width: 550px)

@media (min-width: 768px) {

	.photo-gallery {

		.gallery-thumb {
			float: left;
			width: calc(50% - 10px);
			margin: 0 20px 20px 0;

			&:nth-child(2n) {
				margin-right: 0; }

			&:nth-child(2n+1) {
				clear: both; }

			.image {
				width: 100%;
				height: 240px; } } }

	#directory .institution {
		&.col-4 > * {
			&:nth-child(1), &:nth-child(2) {
				width: calc(30% - 20px) !important; }
			&:nth-child(3), &:nth-child(4) {
				width: calc(20% - 20px) !important; } }

		&.column-labels {
			display: flex !important; } } }




//@media (min-width: 900px)

//@media (min-width: 1024px)

@media (min-width: 1100px) {


	// HOLLYWOOD STYLES
	.animate {
		opacity: 0;
		transition: opacity 0.7s cubic-bezier(0, 0, 0.2, 1), transform 0.7s cubic-bezier(0, 0, 0.2, 1); }

	.fromLeft {
		transform: translate(-50px,0); }

	.fromRight {
		transform: translate(50px,0); }

	.fromBottom {
		transform: translate(0,50px); }

	.fromTop {
		transform: translate(0,-50px); }

	.inRange {
		.animate {
			opacity: 1; }

		& .fromLeft, & .fromRight, & .fromBottom, & .fromTop {
			transform: translate(0,0); } }

	// SCROLL IN AND OUT OF FIXED CENTER STYLES
	/// scrollInAndOutOfFixedCenter STYLES
	.fixToTop, .fixToBottom {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%); }

	.fixToBottom {
		top: auto;
		bottom: 0; }

	.fixToCenter {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); } }



@media (max-width: 767px) {
	#backToTop {
		display: none; } }
