$light-grey: #666666;

.pi-prev-button, .pi-next-button, .pi-modal-close-button, .blips {
	position: absolute;
	padding: 0;
	z-index: 9999999; }

.pi-prev-button, .pi-next-button {
	width: 30px;
	height: 30px;
	background: none;
	border: 0;
	border-top: 1px solid white;
	border-right: 1px solid white;
	top: 50%;
	cursor: pointer;
	transition: opacity 0.3s, border-color 0.3s;

	&:hover {
		border-color: $light-grey; }

	&.off {
		opacity: 0; } }

.pi-prev-button {
	left: 20px;
	transform: translateY(-50%) rotate(-135deg); }

.pi-next-button {
	right: 20px;
	transform: translateY(-50%) rotate(45deg); }

.pi-modal-close-button {
	background: none;
	border: 0;
	top: 20px;
	right: 20px;
	cursor: pointer;
	@include ecks(40);

	&:before, &:after {
		transition: background-color 0.3s; }

	&:hover {
		&:before, &:after {
			background-color: $light-grey; } } }

.pi-overlay {
	@include fullscreen;
	background-color: transparentize(black, 0.2);
	z-index: 999999; }



.blips {
	@include pureCenter(calc(100% - 80px));

	button {
		position: relative;
		padding: 0;
		background: none;
		border: 3px solid #999999;
		width: 15px;
		height: 15px;
		cursor: pointer;
		transition: background-color 0.15s;

		&:hover {
			background-color: #999999; }

		&.on {
			border-color: white;
			background-color: white; }

		&.on:hover {
			background-color: white; } }

	button + button {
		margin-left: 6px; } }



// TODO: spinner
