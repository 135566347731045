

.banner-rotator {
	padding: 0;
	height: 100vh;

	.pi-rotator {
		width: 100%;
		height: 100%;

		.item {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			main {
				height: 100%;
				padding: 10vh 20px; } } } }

@media (min-width: 768px) {

	.banner-rotator {
		height: 600px;

		.pi-rotator {
			.item {
				main {
					padding: 10vh 20px; } } } } }

@media (min-width: 1100px) {

	.banner-rotator {
		height: 700px; } }

@media (min-width: 1200px) {

	.banner-rotator {
		.pi-rotator {
			.item {
				main {
					padding: 10vh 0; } } } }

	.blips {
		max-width: 1200px;
		width: 100%;

		button {
			border-radius: 50%;
			border-width: 0;
			background: transparentize($charcoal, 0.8);

			& + button {
				margin-left: 20px; }

			&:hover {
				background: $charcoal; }

			&.on {
				background: $blue;

				&:hover {
					background: $blue; } } } } }
