

header {
	position: relative;
	width: 100%;
	height: 60px;
	text-align: center;
	z-index: 5;
	background: #fff;
	color: $charcoal;
	padding: 0 20px;

	& > main {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0;
		height: 100%;
		max-width: none; }

	ul, li {
		position: relative;
		list-style: none;
		display: inline-block; }

	&.pi-sticky-header.sticky {
		background: #fff; } }


#logo {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	height: 60%;
	width: 140px;
	background: url('/wp-content/themes/uc/images/logo-elg-black.svg') left center no-repeat;
	background-size: contain;

	img {
		height: calc(100% - 10px);
		display: none; } }

body.reverse-header-text {
	#logo {
		background-image: url('/wp-content/themes/uc/images/logo-elg-white.svg'); } }

nav {
	&.pi-unimenu {
		display: none;
		margin-left: auto; }

	main {
		padding: 0; } }



// MENU BUTTON / HAMBURGER
.menuButton {
	padding: 0;
	color: #fff;
	display: block;
	height: 100%;
	width: 50px;
	float: left;
	text-transform: uppercase;
	position: absolute;
	right: -20px;
	top: 0;
	cursor: pointer; }

.menuButton span {
	display: block;
	line-height: 20px;
	height: 20px;
	width: 100%;
	font-size: 14px;
	font-weight: 400;
	position: absolute;
	bottom: -20px;
	left: 0;
	text-align: center; }

.hamburger {
	display: block;
	height: 4px;
	width: 30px;
	background-color: #000;
	vertical-align: middle;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: all 0.15s ease-out;

	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		height: 4px;
		width: 100%;
		background-color: #000;
		right: 0;
		top: -10px;
		transition: all 0.15s ease-out; }

	&:after {
		top: auto;
		bottom: -10px; } }



//////////////////////////////////
// ALTERNATE HAMBURGER STATES
//////////////////////////////////

// HAMBURGER IN TRANSPARENT (WHITE TEXT) HEADER
body.reverse-header-text {
	.hamburger, .hamburger:before, .hamburger:after {
		background: #fff; } }

// HAMBURGER WHILE HEADER IS SCROLLING
header.sticky, body.reverse-header-text header.sticky {
	.hamburger, .hamburger:before, .hamburger:after {
		background: #000; } }

// HAMBURGER IN OPEN NAV
body.open-nav {

	.hamburger {
		background-color: transparent !important;

		&:before, &:after {
			width: 100%;
			top: 0;
			right: 0;
			transform: rotate(45deg);
			background-color: #fff !important; }

		&:after {
			width: 100%;
			bottom: 0;
			right: 0;
			transform: rotate(-45deg);
			background-color: #fff !important; } } }



//////////////////////////////////
// ALTERNATE NAVIGATION STATES
//////////////////////////////////
body.header-transparent {
	padding-top: 0;

	header {
		background-color: transparent; }

	section:first-of-type.flex-content-section {
		padding-top: 80px; } }


header.sticky {
	box-shadow: 0 0 0 3px rgba(0,0,0,0.2);

	nav.pi-unimenu {
		.menu > li > a {
			color: #000 !important; } }

	#logo {
		background-image: url('/wp-content/themes/uc/images/logo-elg-black.svg'); } }

body.open-nav header.sticky nav.pi-unimenu li a {
	color: #fff !important; }

//////////////////////////////////////
// RESPONSIVE
//////////////////////////////////////

//@media (min-width: 550px)

@media (min-width: 768px) {

	header.pi-sticky-header {
		position: fixed;
		top: 0;
		left: 0;
		height: 80px;
		padding: 0 20px; }

	#logo {
		display: flex;
		flex-direction: row;
		align-items: flex-end;

		img {
			height: calc(100% - 10px); } }

	.menuButton {
		width: 60px; }

	#quickConnectButton {
		display: inline-block; } }

@media (min-width: 900px) {

	header {
		padding: 0 30px; } }

@media (min-width: 1100px) {



	nav.pi-unimenu {
		display: flex;

		main > ul > li:after {
			background: #000; }

		main > ul > li {

			&:after {
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 7.5px 13.0px 7.5px;
				border-color: transparent transparent #000 transparent;
				display: none; }

			& > ul {
				padding-top: 0;
				box-shadow: none;
				left: 50%;
				transform: translateX(-50%);
				width: auto;
				overflow: hidden;

				li {
					&:first-child {
						margin-top: -20px;
						transition: margin 0.2s ease-out; }

					a {
						background: #000;
						color: #fff !important;
						white-space: nowrap; } } }

			&:hover:after, &.current_page_item:after, &.current_page_ancestor:after {
				height: 0; }

			&:hover {
				& > ul li:first-child {
					margin-top: 0; } } }

		.menu > li {
			margin: 0 10px;
			height: 80px;
			line-height: 80px;

			& > a {
				padding: 0 10px;
				text-transform: uppercase;
				font-size: 14px; }


			&:hover > a {
				color: $charcoal; } }

		li a {
			color: $charcoal;
			padding: 5px 10px;
			letter-spacing: 0.08em; } }


	body.reverse-header-text {

		nav.pi-unimenu li a {
			color: #fff;

			&:hover {
				color: #fff; } } } }



@media (min-width: 1200px) {

	header.pi-sticky-header {
		height: 100px;

		&.stuck {
			height: 100px; }
		//
		//&.hidden
 }		//	top: 110px

	#logo {
		//background: url('/wp-content/themes/uc/images/ncu-logo.svg') bottom left no-repeat
		width: 276px; } }
