a.fa {
	font: inherit;
	display: inline-block;
	font-size: 1.25em;
	font-weight: 600;

	&:hover {
		text-decoration: none; } }

.fa:before {
	font-family: "FontAwesome";
	font-weight: normal; }

footer {
	position: relative;
	padding: 0 20px;
	background: #000;
	text-align: left;
	text-transform: uppercase;

	a {
		color: #ffffff;

		&.fa {
			padding: 0 !important;

			& + .fa {
				margin-left: 10px; } } }

	h4 {
		margin: 20px 0; }

	main {
		text-align: center;
		color: white;
		padding: 40px 0;

		.menu {
			display: block;
			overflow: hidden;
			margin: 0 0 10px 0;
			padding: 0;
			line-height: 1.5em;
			list-style: none;

			li {
				display: block;
				margin: 0 0 10px 0;

				&:last-child {
					margin-right: 0;
					border-right: none; } } } }

	.columns {
		margin: 0;

		& > div {
			text-align: left;
			margin: 0;
			padding: 20px 0;

			& + div {
				border: solid #fff;
				border-width: 1px 0 0 0; } } }

	.division {
		padding-left: 60px;
		margin: 15px 0;
		background-position: left center;
		background-size: auto 100%;
		background-repeat: no-repeat;

		&.gfp {
			background-image: url('/wp-content/uploads/2017/03/logo-mark-gfp-white.svg'); }

		&.oks {
			background-image: url('/wp-content/uploads/2017/03/logo-mark-oks-white.svg'); }

		&.catering {
			background-image: url('/wp-content/uploads/2017/03/logo-mark-catering-white.svg'); } } }


#legal {
	padding: 0 30px;
	background: #555;
	color: #fff;
	font-size: 12px;

	main {
		padding: 5px 0; }

	.links {
		float: right;

		ul, li, a {
			list-style: none;
			display: inline-block;
			line-height: 12px; }

		li {
			padding: 0 10px;
			border-left: 1px solid #fff;

			&:first-child {
				border-width: 0; } }

		a:hover {
			text-decoration: underline; } } }


.socNetButtons {
	a {
		display: inline-block;
		height: 30px;
		line-height: 30px;
		font-size: 30px;

		&:hover {
			color: #fff !important; } } }


//footer .columns
//	justify-content: space-between




//////////////////////////////////////
// RESPONSIVE
//////////////////////////////////////

//@media (min-width: 550px)

@media (min-width: 768px) {

	footer {
		padding: 0 30px;

		main {
			flex-direction: row;
			justify-content: center; }

		.columns > div {
			padding: 20px;

			& + div {
				border-width: 0; } } } }


//@media (min-width: 900px)

@media (min-width: 1024px) {

	footer {
		.columns > div {
			padding: 0 20px;

			& + div {
				border-width: 0 0 0 1px; } } } }

@media (min-width: 1100px) {

	footer {
		main p {
			margin: 20px 0; }

		.columns > div {
			padding: 0 50px; } } }

