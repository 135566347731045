$burgerThreshhold: 1100px;




nav.pi-unimenu {
	position: relative;
	opacity: 1;

	main {
		margin: 0;
		padding: 0; }

	ul {
		margin: 0;
		padding: 0; }

	li {
		position: relative;

		& > ul {
			position: absolute;
			top: calc(100%);
			left: 0;
			padding-top: 1px;
			display: block;
			width: 200px;
			min-width: 100%;
			opacity: 0;
			visibility: hidden;
			transition: visibility 0.15s, opacity 0.15s ease-out;
			box-shadow: 3px 3px 0 rgba(0,0,0,0.2);

			li {
				display: block;
				padding: 0;

				a {
					display: block;
					padding: 8px 10px;
					background-color: #fff;
					transition: all 0.15s ease-out;
					//transform: translateY(-10px)
					position: relative;
					line-height: 1.2em;

					&:after {
						content: '';
						display: none;
						opacity: 0.2;
						width: 6px;
						height: 6px;
						border: solid $dark-blue;
						border-width: 2px 2px 0 0;
						position: absolute;
						top: 50%;
						right: 10px;
						transform: translateY(-50%) rotate(45deg); } }

				&:hover > a {
					background-color: $dark-blue;
					color: #fff; }

				&.page_item_has_children > a:after {
					display: block; }

				ul {
					top: -1px;
					left: calc(100% + 1px); } } }

		a {
			display: inline-block;
			padding: 10px 8px;
			width: 100%;
			position: relative;
			z-index: 2;

			&:hover {
				text-decoration: none; } }

		&:hover {

			& > ul {
				opacity: 1;
				visibility: visible;
				transition: visibility 0s, opacity 0.15s ease-out; }

			a {
				color: $dark-blue; }

			li a {
				transform: translateY(0); } } }

	main > ul > li {
		&:after {
			content: '';
			display: block;
			background: #fff;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 0;
			opacity: 0;
			transition: all 0.15s ease-out; }

		&:hover:after {
			height: 100%;
			opacity: 1; } }

	& > ul {
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 0;
		height: auto !important; }

	& > ul > li + li {
		margin-left: 30px; }

	& > ul > li:hover ul {
		height: auto; } }




.pi-burger-button {
	display: none;
	//position: absolute
	//top: 0
	//right: 0
	//width: 60px
	height: 100%;
	background: transparent;
	border: none;
	color: #eeeeee;
	z-index: 99999999999;
	position: relative;
	width: 30px;
	padding: 0;
	margin: 0 0 0 20px;

	&:hover {
		background: transparent; } }


@media screen and (max-width: $burgerThreshhold) {

	.pi-burger-button {
		display: block; }

	nav.pi-unimenu {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: auto;
		padding: 20px;
		opacity: 0;
		transition: opacity 0.5s;

		main {
			margin: auto;
			text-align: center;

			& > * {
				text-align: left; }

			ul, li, a {
				height: auto;
				display: inline-block;
				background-color: transparent;
				color: #fff; }

			ul {
				padding-left: 10px;
				position: relative;
				top: auto;
				left: auto;
				box-shadow: none;
				opacity: 1 !important;
				visibility: visible !important;

				li {
					display: block;

					&:after {
						display: none; }

					a {
						padding: 5px 0;
						line-height: 1.2em;
						font-size: 22px;
						display: block;
						background-color: transparent !important;
						transform: none !important; }

					li a {
						font-size: 16px;
						opacity: 0.7; }

					&:hover a {
						color: #fff; } } }

			& > ul {
				column-count: 3;
				column-gap: 40px;

				& > li {
					padding-bottom: 20px;
					display: block;
					break-inside: avoid;

					ul {
						top: auto;
						left: auto; } } } } }

	body.open-nav {
		overflow: hidden;

		& nav.pi-unimenu {
			display: flex;
			position: fixed;
			margin: 0 !important;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background-color: $charcoal;

			& > ul {
				display: block;
				@include pureCenter(); }


			& > ul > li + li {
				margin-left: 0 !important; } } } }

@media screen and (max-width: 760px) {

	nav.pi-unimenu main > ul {
		column-count: 1; } }

@media screen and (max-width: 760px) and (orientation: landscape) {

	nav.pi-unimenu main > ul {
		column-count: 2; } }
