




input[type=text], input[type=password], input[type=email], input[type=number], input[type=tel], input[type=date], textarea, select {
	margin: 0;
	padding: 5px 10px;
	font-size: 16px;
	color: #888;
	line-height: 1.5em;
	border: 1px solid #888;
	background: rgba(255,255,255,0.7);
	border-radius: 0;
	outline: none;
	max-width: 100%;
	transition: all 0.2s;

	&:focus {
		background-color: #fff;
		border-color: $blue;
		box-shadow: 0 0 0 3px transparentize($blue, 0.3); } }

select {
	-webkit-appearance: none;
	padding-right: 30px;
	background-image: url('/wp-content/themes/uc/images/selectArrow.png');
	background-repeat: no-repeat;
	background-position-x: calc(100% - 10px);
	background-position-y: center; }

label {
	color: black;
	display: block;
	margin-bottom: 15px;

	span {
		display: block;
		margin-bottom: 5px;
		font-weight: bold; } }


input[type=submit], input[type=reset], input[type=button], button, .button {
	font-size: 0.75em;
	font-weight: 500;
	font-family: $baseFont;
	text-transform: uppercase;
	display: inline-block;
	padding: 8px 20px;
	background-color: transparent;
	color: $charcoal;
	transition: 0.3s;
	border: 2px solid $charcoal;
	outline-width: 0;
	cursor: pointer;
	margin: 5px 0;
	border-radius: 0;

	&:hover {
		text-decoration: none;
		color: white;
		background-color: $charcoal; } }


.white-text {
	input[type=submit], input[type=reset], input[type=button], button, .button {
		border-color: #ffffff;
		color: #fff;

		&:hover {
			background: #fff;
			color: #000; } } }


#registration {
	fieldset {
		border: 0;
		margin-bottom: 50px; }

	.text-label, .checkbox-label, .labelradio {
		display: block;
		font-weight: bold; }

	.text-label span {
		display: inline-block; }

	.labelradio {
		line-height: 1.5em; }

	.text-label input[type=text], .checkbox-label input[type=text], .text-label textarea {
		display: block; }

	.checkbox-label input[type=text], .text-label textarea {
		width: auto;
		margin-left: 20px; }

	.radiogroup br {
		display: none; }

	input[type=text] {
		width: 100%; } }

.formRow + .formRow {
	margin-top: 20px; }

.screen-reader {
	display: none; }



// NINJA FORMS
.nf-input-limit, .nf-form-fields-required {
	display: none; }

.nf-field-container {
	margin-bottom: 20px !important; }

.label-above .nf-field-label {
	margin-bottom: 5px !important; }

.ninja-forms-req-symbol, .nf-error-msg {
	display: inline; }

.nf-after-field {
	position: absolute;
	top: 0;
	right: 0;

	.nf-error-msg {
		margin-right: 0; } }

//////////////////////////////////////
// RESPONSIVE
//////////////////////////////////////

//@media (min-width: 550px)

//@media (min-width: 768px)

//@media (min-width: 900px)

//@media (min-width: 1024px)

//@media (min-width: 1100px)
