


// SECTIONS
section {
	position: relative;
	padding: 0 20px;

	ul, ol {
		margin: 10px 0 20px 0;

		& > li {
			margin: 0 0 5px 0;
			position: relative;
			line-height: 1.5em; } }

	ul li {
		list-style-type: square; } }

main {
	width: 100%;
	max-width: 1200px;
	text-align: left;
	margin: 0 auto;
	padding: 20px 0;
	position: relative; }


// MAIN CONTENT
.mainContent {
	position: relative;
	word-wrap: break-word;
	border-bottom: 1px solid #ccc;

	main {

		.pageTitle {
			border-bottom: 1px solid #ccc;
			padding-bottom: 10px; } } }





// VIDEO BG
.videoBG {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	video {
		opacity: 0;
		transition: opacity 0.5s 2s;
		display: none;

		&.on {
			opacity: 1; } } }



/////////////////////////////
// FLEX TEXT ALIGNING
/////////////////////////////
.flex-content-section {

	main {
		display: block; } }

.flex-text-align {
	display: flex; }

.flex-align-left {
	justify-content: flex-start;
	text-align: left; }

.flex-align-center {
	justify-content: center;
	text-align: center; }

.flex-align-right {
	justify-content: flex-end;
	text-align: right; }

.flex-align-top {
	align-items: flex-start; }

.flex-align-middle {
	align-items: center; }

.flex-align-bottom {
	align-items: flex-end; }

/////////////////////////////
// CONTENT
/////////////////////////////
.flexContent {
	border-bottom: 1px solid #ccc; }

.content {
	margin: 0 0 40px 0;
	width: 100%;

	&:last-child {
		margin-bottom: 0; } }


/////////////////////////////
// COLUMNS
/////////////////////////////
.columns {
	display: flex;
	justify-content: center;
	align-items: stretch;
	flex-wrap: wrap;
	padding: 0;
	margin: 0 0 -20px 0;

	& > * {
		display: block;
		list-style: none;
		margin: 0 0 20px 0;
		width: 100%;
		order: 1;

		img {
			max-width: 100%;
			margin-bottom: 0; } } }

.verticalCenter {
	display: flex;
	justify-content: center;
	flex-direction: column; }

.vertical-align-top {
	align-items: flex-start; }

.vertical-align-middle {
	align-items: center; }

.vertical-align-bottom {
	align-items: flex-end; }

.vertical-align-stretch {
	align-items: stretch; }

.columns-inline {
	column-count: 1;
	column-gap: 40px;

	& > * {
		display: inline-block;
		break-inside: avoid; } }

.imageOnly {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover; }

.narrow {
	max-width: 600px;
	margin: 0 auto; }

.has-bg-image {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover; }

.white-text {
	background-color: #000;

	h1, h2, h3, h4, li, blockquote {
		color: #fff !important; }

	blockquote p {
		color: #fff !important; } }

.justify-space-between {
	.columns {
		justify-content: space-between;

		& > div {
			margin: 0 0 20px 0; } } }


/////////////////////////////
// FULL-WIDTH / 2-COLUMN
/////////////////////////////
.full-width {
	padding: 0;

	main {
		max-width: none;
		padding: 0 !important;
		justify-content: space-between;

		.columns {
			margin-bottom: 0;
			justify-content: space-between;

			& > * {
				padding: 20px;
				margin: 0 !important;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat; } }

		.imageOnly {
			padding: 0;

			img {
				width: 100%; } } } }

/////////////////////////////
// FAQ
/////////////////////////////
.faq {
	.q-and-a {
		margin-bottom: 20px;
		position: relative;

		.question, .answer {
			padding-left: 24px;
			position: relative;

			&:before {
				content: 'Q:';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				font-weight: 800;
				font-size: 18px !important; } }

		.answer:before {
			content: 'A:'; }

		.question {
			color: #000;
			font-weight: bold;
			font-size: 22px;
			line-height: 1.5em; } } }












//////////////////////////////////////
// RESPONSIVE
//////////////////////////////////////

//@media (min-width: 550px)

@media (min-width: 768px) {

	section {
		padding: 0 30px; }

	main {
		padding: 30px 0; }

	#breadcrumb {
		b, strong {
			display: inline-block; } }

	.columns {
		justify-content: space-between;

		& > * {
			width: calc(50% - 20px);
			margin: 0 0 20px 0; } }

	.full-width {
		main {
			.columns > * {
				padding: 30px;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center; }

			.imageOnly {
				padding: 0;

				img {
					width: 100%; } } } }

	.photo-gallery {
		.gallery-thumb {
			float: left;
			width: calc(50% - 10px);
			margin: 0 20px 20px 0;

			&:nth-child(2n) {
				margin-right: 0; }

			&:nth-child(2n+1) {
				clear: both; }

			.image {
				width: 100%;
				height: 240px; } } } }



@media (min-width: 800px) {
	.alignleft, .alignright, .aligncenter, .alignnone {
		margin: 5px 0 20px 0;
		width: auto !important;
		max-width: 50%;
		display: block;

		img {
			width: auto;
			max-width: 100%; } }

	.alignleft {
		float: left;
		margin-right: 20px; }

	.alignright {
		float: right;
		margin-left: 20px; }

	.aligncenter {
		margin: 5px auto 20px auto;
		text-align: center;
		max-width: 100%;
		height: auto;

		img {
			margin: 0 auto;
			max-width: 100%;
			height: auto; } } }


@media (min-width: 1024px) {

	.columns {
		//& > *
		//	width: calc(50% - 40px)
		//&.col-2 > *
		//	margin: 20px
		&.col-3 > * {
			width: calc(33.33333% - 20px) !important; }
		&.col-5 > * {
			width: calc(33.33333% - 20px) !important; }
		&.col-6 > * {
			width: calc(33.33333% - 20px) !important; } }

	.columns-inline {
		column-count: 2;
		column-gap: 30px; }

	.full-width {
		main {
			.columns {
				overflow: hidden;

				&.sizes-50-50 > * {
					width: 50%;

					&.imageOnly + .right.textContent {
						margin-left: 50% !important; } }

				&.sizes-33-66 {
					& > *:nth-child(1) {
						width: 33.33333% !important; }
					& > *:nth-child(2) {
						width: 66.66666% !important; }
					.imageOnly + .right.textContent {
						margin-left: 33.33333% !important; } }

				&.sizes-66-33 {
					& > *:nth-child(1) {
						width: 66.66666% !important; }
					& > *:nth-child(2) {
						width: 33.33333% !important; }
					.imageOnly + .right.textContent {
						margin-left: 66.66666% !important; } }

				.imageOnly {
					padding: 0;
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;

					&.right {
						left: auto;
						right: 0; }

					img {
						width: 100%;
						display: none; } } } } }


	// scrollInAndOutOfFixedCenter STYLES
	.fixToTop, .fixToBottom {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%); }

	.fixToBottom {
		top: auto;
		bottom: 0; }

	.fixToCenter {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); } }


@media (min-width: 1100px) {

	.min-height main {
		display: flex !important;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: 100%; }

	.min-height-40 {
		height: 40vh; }

	.min-height-50 {
		height: 50vh; }

	.min-height-60 {
		height: 60vh; }

	.min-height-70 {
		height: 70vh; }

	.min-height-80 {
		height: 80vh; }

	.min-height-90 {
		height: 90vh; }

	.min-height-100 {
		height: 100vh; }

	// BLOG
	body.blog-page {
		#mainContent main {
			.content {
				width: calc(75% - 40px); } } }

	section {
		padding: 0 5vw; }

	main {
		padding: 80px 0; }

	.columns {
		&.col-2 {
			& > * {
				width: calc(50% - 2vw); }
			&.sizes-33-66 {
				& > *:nth-child(1) {
					width: calc(33.33333% - 5vw) !important; }
				& > *:nth-child(2) {
					width: calc(66.66666% - 5vw) !important; } }
			&.sizes-66-33 {
				& > *:nth-child(1) {
					width: calc(66.66666% - 5vw) !important; }
				& > *:nth-child(2) {
					width: calc(33.33333% - 5vw) !important; } } }
		&.col-4 > * {
			width: calc(25% - 2vw) !important; } }

	.columns-inline {
		column-gap: 40px; }


	// HOLLYWOOD STYLES
	.animate {
		opacity: 0;
		transition: opacity 0.4s cubic-bezier(0, 0, 0.2, 1), transform 0.4s cubic-bezier(0, 0, 0.2, 1); }

	.fromLeft {
		transform: translate(-50px, 0); }

	.fromRight {
		transform: translate(50px, 0); }

	.fromBottom {
		transform: translate(0, 50px); }

	.fromTop {
		transform: translate(0, -50px); }

	.inRange.animate {
		opacity: 1;

		&.fromLeft, &.fromRight, &.fromBottom, &.fromTop {
			transform: translate(0, 0); } }

	.full-width {
		main {
			.columns > * {
				padding: 80px 10vh; }

			.imageOnly {
				padding: 0;

				img {
					width: 100%; } } } }

	.backgroundVideo {
		display: block; } }



@media (min-width: 1200px) {
	.columns {
		&.col-5 > * {
			width: calc(20% - 20px) !important; }
		&.col-6 > * {
			width: calc(16.666666% - 20px) !important; } } }

@media (min-width: 1200px) {
	.columns {
		&.col-5 > * {
			width: calc(20% - 20px) !important; }
		&.col-6 > * {
			width: calc(16.666666% - 20px) !important; } } }



@media (min-width: 1500px) {

	.childPageTiles {
		main {
			.columns {
				a {
					width: 33.3333333% !important;
					height: 15vw;

					.text {
						order: 0;

						h3 {
							font-size: 1.6vw; } }

					&:nth-child(4n-1), &:nth-child(4n) {
						.text {
							order: 0; } }

					&:nth-child(6n+4), &:nth-child(6n+5), &:nth-child(6n+6) {
						.text {
							order: 1; } } } } } } }


@media (max-width: 1100px) {

	.force-mobile-align-center {
		main {
			justify-content: center;
			text-align: center; } } }

@media (max-width: 767px) {

	.imageOnly {
		order: 2; } }
