

body.noScroll {
	overflow: hidden; }


#rotateYourDevice {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #eee;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 100;
	text-align: center;
	text-transform: uppercase;
	display: none;

	img {
		max-width: 50%;
		height: auto;
		margin-bottom: 10px; } }


body.home header {
	display: none;

	main {
		transition: opacity 0.5s ease-out; }

	&.loading {
		main {
			opacity: 0; }

		#emergencyButton {
			transform: translateX(100%); } } }




#emergencyButton, #emergencyButton2 {
	width: 50px;
	height: 50px;
	background: #b20b0b url('/wp-content/uploads/2017/04/icon-phone.svg') center center no-repeat;
	background-size: 32px;
	position: absolute;
	top: 100%;
	right: 0;
	cursor: pointer;
	transition: all 0.2s ease-out, opacity 0.2s ease-out, visibility 0s 0s;
	visibility: visible;
	opacity: 1;
	display: none;

	&:hover {
		background-color: #cc0b0b; } }

#emergencyButton2 {
	background-image: none;
	width: auto;
	height: auto;
	padding: 10px 40px 10px 20px;
	text-align: center;
	cursor: default;
	transform: translateX(100%);

	&:hover {
		background-color: #b20b0b; }

	h4, a {
		display: block;
		color: #fff;
		margin: 0; }

	a {
		font-size: 1.65em;

		&:hover {
			text-decoration: none;
			cursor: default; } }

	&.on {
		transform: translateX(0%); } }

#emtCloseButton {
	position: absolute;
	top: 0;
	right: 0;
	width: 30px;
	height: 100%;
	display: block;
	cursor: pointer;

	&:before, &:after {
		content: '';
		display: block;
		background: #fff;
		height: 1px;
		width: 60%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg); }

	&:after {
		transform: translate(-50%, -50%) rotate(45deg); } }

body.home {
	#emergencyButton, #emergencyButton2 {
		display: block; } }

.scrolled {
	#emergencyButton, #emergencyButton2 {
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.2s ease-out, visibility 0s 0.2s; } }






// DEFAULT PAGE TITLE
#defaultPageTitle {
	background-color: $dark-blue; }

// FOR SIZING VARIOUS ICONS IN WYSIWYG
.icon {
	width: 72px !important;
	height: auto !important; }

// FOR SIZING VARIOUS ICONS IN WYSIWYG
.big-icon {
	max-width: 150px !important;
	height: auto !important; }


// HOME PAGE STUFF
#homeBanner {
	background-color: #0d0e12;
	overflow: hidden;
	height: 100vh;
	max-height: 100vh; }

#mountains {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: translateY(-90%);
	opacity: 0;
	transition: transform 2s cubic-bezier(0.42, 0, 0.14, 1), opacity 1s 1s cubic-bezier(0.42, 0, 0.14, 1);
	background: #0d0e12 url('/wp-content/uploads/2017/04/mountains-mobile.jpg') center center no-repeat;
	background-size: cover;

	& > div {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transition: transform 2s cubic-bezier(0.42, 0, 0.14, 1), opacity 0.5s cubic-bezier(0.42, 0, 0.14, 1);
		display: none;

		&:nth-child(1) {
			background-image: url("/wp-content/uploads/2017/03/mountain-1.jpg");
			transform: translateY(30%);

			div {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: url("/wp-content/uploads/2017/03/mountain-2.png") center center no-repeat;
				background-size: cover;
				opacity: 0;
				transition: opacity 1s 2s ease-out;
				display: none; } }

		&:nth-child(2) {
			background-image: url("/wp-content/uploads/2017/03/mountain-3.png");
			transform: translateY(22%); }

		&:nth-child(3) {
			background-image: url("/wp-content/uploads/2017/03/mountain-4.png");
			transform: translateY(5%);

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 99%;
				left: 0;
				background: #0d0e12; } } }

	#tagline {
		width: 100%;
		height: 100%;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 20px 25vw 20px;
		opacity: 0;
		transition: none;
		//transition: opacity 0.5s 0s ease-out

		h2 {
			text-transform: none;
			font-size: 4vw;
			margin-bottom: 0;
			font-family: $baseFont;
			letter-spacing: 0;
			color: #002e5d;

			span {
				font-size: 6vw;
				line-height: 1em;
				transition: opacity 0.5s 2.5s ease-out;
				opacity: 0;

				&:nth-of-type(2) {
					transition: opacity 0.5s 3.5s ease-out; } } } }


	&.start {
		transform: translateY(0);
		opacity: 1;
		transition: transform 2s cubic-bezier(0.42, 0, 0.14, 1), opacity 1s cubic-bezier(0.42, 0, 0.14, 1);

		& > div {
			transform: translateY(0); }

		#tagline {
			opacity: 1;

			span {
				opacity: 1; } } }

	&.noAnimation div {
		transition: none !important; } }



#banner {
	padding-bottom: 20px;

	h1 {
		font-size: 3em; }

	h4 {
		text-transform: uppercase;
		letter-spacing: 0.09em;
		font-size: 2em;
		line-height: 1.2em;
		font-weight: normal; }
	p {
		max-width: 600px;
		margin: 0 auto;
		font-size: 1.2em;
		line-height: 1.5em; } }







#target {
	width: 0;
	height: 0;
	position: absolute;
	top: 71%;
	left: 42%;
	transition: top 1s cubic-bezier(0, 0, 0.21, 1), left 1s cubic-bezier(0, 0, 0.21, 1);

	&.loading {
		top: 110%; }

	& > * {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); }

	#blip {
		width: 16px;
		height: 16px;
		border: 1px solid #ffffff;
		background: #fff;
		transform: translate(-50%, -50%) rotate(45deg);
		transition: all 0.4s ease-out; }

	#rays {
		width: 15vw;
		height: 15vw;
		opacity: 1;
		transition: opacity 0.5s ease-out;

		.ray {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			border: 2px solid #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
			transition: opacity 0.2s 0.2s ease-out, transform 0.2s 0.2s cubic-bezier(0, 0, 0.21, 1);

			&:nth-child(2) {
				width: 80%;
				height: 80%;
				transition: opacity 0.2s 0.1s ease-out, transform 0.2s 0.1s cubic-bezier(0, 0, 0.21, 1); }

			&:nth-child(3) {
				width: 60%;
				height: 60%;
				transition: opacity 0.2s 0s ease-out, transform 0.2s 0s cubic-bezier(0, 0, 0.21, 1); } }

		&.flash {
			.ray {
				animation-name: flash;
				animation-delay: 0.25s;
				animation-iteration-count: infinite;
				animation-duration: 2s;

				&:nth-child(2) {
					animation-delay: 0.1s; }

				&:nth-child(3) {
					animation-delay: 0s; } } } }

	&.outOfView {

		#blip {
			opacity: 0;
			transform: translate(-50%, -50%) rotate(180deg);
			transition: all 0.3s ease-out; }

		#rays {
			opacity: 0; } } }





#targetLine {
	width: 100vw;
	height: 0;
	position: absolute;
	top: 71%;
	left: 42%;

	&:before {
		content: '';
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		height: 1px;
		width: 100%;
		background: #fff;
		transition: width 1s 0s cubic-bezier(0, 0, 0.21, 1); }

	&.loading, &.outOfView {
		&:before {
			width: 0; } } }


@keyframes flash {
	0% {
		opacity: 0; }
	10% {
		opacity: 1; }

	25% {
		opacity: 0.4; }

	100% {
		opacity: 0; } }









//////////////////////////////////////
// CHILD PAGE TILES
//////////////////////////////////////
.childPageTiles {
	background: $dark-blue;

	main {
		.columns {
			a {
				width: 100%;
				height: auto;
				padding: 0 !important;
				margin: 0 !important;
				background-color: #002142;
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				transition: background-color 0.15s ease-out;
				position: relative;
				overflow: hidden;

				&:after {
					content: '';
					width: 60px;
					height: 60px;
					background-color: #002142;
					position: absolute;
					top: calc(70vw - 40px);
					left: 0;
					transform-origin: 0 0;
					transform: rotate(45deg);
					transition: background-color 0.15s ease-out;
					z-index: 1;
					display: block; }

				&:hover {
					background-color: #00458c;
					text-decoration: none;

					&:after {
						background-color: #00458c; }

					.text {
						background-color: #00458c; }

					.image {
						transform: translate(-1%, -1%) scale(1.1); } }

				& > * {
					overflow: hidden;
					transition: all 0.15s ease-out;
					width: 100%; }

				.text {
					position: relative;
					padding: 20px;
					background-color: #002142;
					color: rgba(255,255,255,0.5);
					z-index: 2;
					order: 2;
					min-height: 100%;

					h3 {
						font-weight: 700;
						line-height: 1.2em;
						margin-bottom: 5px;
						font-size: 24px;
						text-transform: none; } }

				.image {
					background-color: white;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					height: 70vw; } } } } }









// CASE STUDIES
#csBanner {
	.content {
		margin-bottom: 20px;
		text-align: center; }

	.left, .right {
		margin-bottom: 0;
		width: 100%; }

	.left img {
		height: 72px;
		margin-top: 1em; } }

.cs-section {
	main {
		max-width: 860px;
		padding: 30px 0; } }

.cs-section-narrow {
	main {
		max-width: 660px;
		padding: 30px 0; } }

.cs-columns {
	.columns {
		justify-content: space-between; }

	.left {
		width: 72px;
		text-align: right; }

	.right {
		width: calc(100% - 100px); } }

.cs-stats {
	background: #efefef;
	text-align: center;

	h3 {
		color: $dark-blue; }

	.content-basic {
		text-align: center; }

	.columns {
		text-align: center;
		align-items: flex-end;
		justify-content: center;

		& > div {
			color: $dark-blue;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 1.3em;
			max-width: 200px;

			p {
				margin-bottom: 0; }

			h3 {
				font-family: $baseFont;
				font-weight: 700;
				font-size: 40px;
				color: $dark-blue;
				margin-bottom: 0; } } } }










#companyHistory {
	background: #efefef;

	main {
		max-width: 800px; }

	h1 {
		color: $dark-blue;
		text-align: center; }

	.columns {
		align-items: stretch;
		margin: 0;
		position: relative;
		font-size: 20px;

		&:before {
			content: '';
			display: block;
			width: 2px;
			height: 100%;
			background: $blue;
			position: absolute;
			top: 0;
			left: 0; }

		& > * {
			margin: 0;
			padding: 10px 0 10px 20px;
			overflow: hidden;

			&.noImage {
				padding: 0; }

			&.left.theImage {
				order: 2; }

			h3 {
				font-family: $baseFont;
				font-weight: 600;
				letter-spacing: -0.01em;
				color: $blue;
				position: relative;

				&:before {
					content: '';
					display: block;
					width: 800px;
					height: 2px;
					background: $blue;
					position: absolute;
					top: 50%;
					right: 100%; }

				span {
					display: inline-block;
					padding: 0 10px;
					background: $blue;
					color: #efefef; } } } } }














@media (min-width: 550px) {

	.childPageTiles {
		main {
			.columns {
				a {
					height: 40vw;

					&:after {
						top: 0;
						left: 50%; }

					& > * {
						width: 50%; }

					.text {
						order: 0; }

					.image {
						height: 100%; }

					&:nth-child(even) .text {
						order: 2; } } } } } }




@media (min-width: 768px) {

	#homeBanner {
		height: 60vw; }

	#banner {
		padding-bottom: 40px;

		h1 {
			font-size: 4em; } }

	.childPageTiles {
		main {
			.columns {
				a {
					.text {
						padding: 25px;

						h3 {
							font-size: 40px; } } } } } }

	#csBanner {
		main {
			max-width: 860px; }

		.content {
			text-align: left; }

		.left, .right {
			margin-bottom: 0; }

		.left {
			width: 72px;
			height: auto; }

		.right {
			width: calc(100% - 100px); } }


	#companyHistory {
		.columns {
			font-size: 20px;

			&:before {
				left: calc(50% - 1px); }

			& > * {
				padding: 10px 0 10px 20px;
				width: 50%;

				&.left {
					padding: 10px 20px 10px 0;
					text-align: right;

					h3:before {
						right: auto;
						left: 100%; } }

				&.left.theImage {
					order: 1; }

				h3 {
					&:before {
						content: '';
						display: block;
						width: 800px;
						height: 2px;
						background: $blue;
						position: absolute;
						top: 50%;
						right: 100%; } } } } }

	#mountains > div {
		display: block; } }




@media (min-width: 1024px) {

	.childPageTiles {
		main {
			.columns {
				a {
					width: 50% !important;
					height: 25vw;

					&:after {
						top: 0;
						left: calc(50%); }

					.text {
						order: 0;
						font-size: 14px;
						line-height: 22px;

						h3 {
							font-size: 2.5vw; } }

					&:nth-child(even) {
						.text {
							order: 0; } }

					&:nth-child(4n-1), &:nth-child(4n) {
						.text {
							order: 1; } } } } } } }

@media (min-width: 1100px) {

	#banner {
		padding-bottom: 80px;

		h1 {
			font-size: 5em; } } }

@media (min-width: 1500px) {

	.childPageTiles {
		main {
			.columns {
				a {
					width: 33.3333333% !important;
					height: 15vw;

					.text {
						order: 0;

						h3 {
							font-size: 1.6vw; } }

					&:nth-child(4n-1), &:nth-child(4n) {
						.text {
							order: 0; } }

					&:nth-child(6n+4), &:nth-child(6n+5), &:nth-child(6n+6) {
						.text {
							order: 1; } } } } } } }




@media (max-width: 1023px) {

	#eventSupport .right {
		order: 0; } }


//@media (max-width: 767px) and (orientation : landscape)
@media (max-width: 767px) {

	#target {
		left: 50%;
		top: auto;
		bottom: 15vh;

		#rays {
			width: 40vw;
			height: 40vw; } }

	#targetLine {
		left: 50%;
		top: auto;
		bottom: 15vh; } }


@media (max-width: 767px) and (orientation : portrait) {

	#mountains {
		background-position: top center;
		background-size: cover;

		#tagline {
			padding: 0 15vw 50vw;

			h2 {
				font-size: 7.5vw;

				span {
					font-size: 2em; } } } } }


@media (max-width: 767px) and (orientation: landscape) {
	html, body {
		overflow: hidden !important;
		height: 100vh !important; }

	#rotateYourDevice {
		display: flex; } }
