$imagesDirectory: "/wp-content/themes/uc/images/";

@function imageUrl($imageName) {
	@return url($imagesDirectory + $imageName); }


@function sqrt($r) {
	$x0: 1;
	$x1: $x0;

	@for $i from 1 through 10 {
		$x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
		$x0: $x1; }

	@return $x1; }

// creates an 'x' on a square element
@mixin ecks($side-length: 80, $tint: white) {
	width: #{$side-length}px;
	height: #{$side-length}px;

	$hypotenuse: sqrt(2 * $side-length * $side-length);

	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		background-color: $tint;
		height: 5px;
		width: #{$hypotenuse}px; }

	&:before {
		left: 0;
		transform-origin: left center;
		transform: rotate(45deg); }

	&:after {
		right: 0;
		transform-origin: right center;
		transform: rotate(-45deg); } }

// exact center of the parent element, or exactly centered on specified coords
@mixin pureCenter ($top: 50%, $left: 50%) {
	position: absolute;
	top: $top;
	left: $left;
	transform: translate(-50%, -50%); }

// fixed, full screen
@mixin fullscreen () {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

// right triangle
@mixin pointy($direction, $sideLength, $color) {
	$top: if($direction == up, 0, $sideLength);
	$right: if($direction == right, 0, $sideLength);
	$bottom: if($direction == down, 0, $sideLength);
	$left: if($direction == left, 0, $sideLength);
	$topColor: if($direction == down, $color, transparent);
	$rightColor: if($direction == left, $color, transparent);
	$bottomColor: if($direction == up, $color, transparent);
	$leftColor: if($direction == right, $color, transparent);
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: $top $right $bottom $left;
	border-color: $topColor $rightColor $bottomColor $leftColor; }
