// TODO: need to figure out the best z-indexing strategy
// so that inline rotators don't "snap out"

.pi-rotator {
	display: none;
	user-select: none;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.3s;

	&.on {
		opacity: 1; }

	.stage, .sled {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		transition: left 0.15s; }

	.stage {
		z-index: 0;
		left: 0; }

	.sled {
		z-index: 1;
		left: 100%; }

	.pi-prev-button, .pi-next-button {
		z-index: 5; }

	.item {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		max-width: 80%;
		max-height: 80%;
		@include pureCenter;

		img {
			max-width: 100%;
			max-height: 100%; } }

	.counter {
		position: absolute;
		right: 10px;
		bottom: 10px;
		color: white;
		z-index: 999999;

		span:first-child:after {
			content: 'of';
			display: inline-block;
			margin: 0 5px; } }


	&.inline {
		display: block;
		opacity: 1;
		position: relative;
		background-color: #fff;
		overflow: hidden;
		z-index: 0;

		.item {
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;

			img {
				@include pureCenter; } }

		.pi-prev-button, .pi-next-button {
			border-width: 3px; } }

	&.crossfade {
		.stage, .sled {
			left: 0;
			transition: opacity 0.7s; } } }
